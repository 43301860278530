import { useQueryClient } from '@tanstack/vue-query';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useRouter } from 'vue-router';

import { getDefaultProject } from '@/entities/project';
import type { Team } from '@/entities/team';

import { TEAM_COOKIE_CONFIG } from '../config';

export function useTeamSwitch() {
  const queryClient = useQueryClient();
  const router = useRouter();
  const cookies = useCookies(['team'], { autoUpdateDependencies: true });

  async function switchToTeam(nextTeam: Team) {
    queryClient.removeQueries({ queryKey: ['project'] });

    queryClient.setQueryData(['team', nextTeam.uuid], nextTeam);

    cookies.set('team', nextTeam.uuid, TEAM_COOKIE_CONFIG);

    const project = await queryClient.ensureQueryData({
      queryKey: ['project', 'default'],
      queryFn: () => getDefaultProject().then((data) => data.project),
      gcTime: Infinity,
      staleTime: Infinity,
    });

    queryClient.setQueryData(['project', project.uuid], project);

    await queryClient.invalidateQueries({ queryKey: ['projects'] });

    clearCacheQueries();

    router.push({
      name: 'project',
      params: { project_uuid: project.uuid },
    });
  }

  async function switchToPersonalAccount() {
    queryClient.removeQueries({ queryKey: ['team'] });
    queryClient.removeQueries({ queryKey: ['project'] });

    cookies.remove('team', TEAM_COOKIE_CONFIG);

    const project = await queryClient.ensureQueryData({
      queryKey: ['project', 'default'],
      queryFn: () => getDefaultProject().then((data) => data.project),
      gcTime: Infinity,
      staleTime: Infinity,
    });

    queryClient.setQueryData(['project', project.uuid], project);

    await queryClient.invalidateQueries({ queryKey: ['projects'] });

    clearCacheQueries();

    router.push({
      name: 'project',
      params: { project_uuid: project.uuid },
    });
  }

  // Clear all queries except the default queries
  function clearCacheQueries() {
    const exceptQueryKey = ['project', 'projects', 'profile', 'team', 'teams', 'notifications'];

    queryClient.removeQueries({
      predicate(query) {
        return !exceptQueryKey.some((key) => query.queryKey.includes(key));
      },
    });
  }

  return { switchToTeam, switchToPersonalAccount };
}
