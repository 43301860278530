import { useQuery } from '@tanstack/vue-query';

import { getProjects } from '@/entities/project';

export function useProjects() {
  return useQuery({
    queryKey: ['projects'],
    queryFn: () => getProjects().then((response) => response.projects),
    staleTime: Infinity,
  });
}
