<template>
  <aside
    ref="container"
    :class="[
      'bg-background fixed bottom-0 left-0 top-16 z-30 flex -translate-x-[16rem] flex-wrap border-r transition-transform lg:translate-x-0',
      isSidebarOpened ? 'translate-x-0' : '',
    ]"
  >
    <div class="flex h-full w-52 flex-1 flex-col">
      <main class="flex flex-1 flex-col justify-between overflow-y-auto overflow-x-hidden">
        <div>
          <NavLinks />
          <AdditionalLinks />
          <div v-if="isMember && !isVerified" class="px-2">
            <UnlockFeatures />
          </div>
        </div>
      </main>
      <footer class="space-y-4 p-4 text-center">
        <ThemeSwitcher />
      </footer>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useAppStore } from '@/app/store';
import { useProfile, useProfileStatus } from '@/entities/profile';
import { useClickAway } from '@/shared/hooks';
import ThemeSwitcher from '@/shared/ui/theme-switcher.vue';

import AdditionalLinks from './additional-links.vue';
import NavLinks from './nav-links.vue';
import UnlockFeatures from './unlock-features.vue';

const { isSidebarOpened } = storeToRefs(useAppStore());
const { element: container } = useClickAway(() => {
  isSidebarOpened.value = false;
});
const { data: profile } = useProfile();
const { isMember, isVerified } = useProfileStatus(profile);
</script>
