import type { UseQueryOptions } from '@tanstack/vue-query';
import { useQuery } from '@tanstack/vue-query';

import type { RequestMeta } from '@/shared/api';

import { getNotifications } from '../api';
import { notificationsQueries } from '../queries';
import type { Notification } from '../types';

export function useNotifications(
  params?: RequestMeta,
  options?: Omit<UseQueryOptions<Notification[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: notificationsQueries.list(),
    queryFn: () => getNotifications(params ?? {}).then((response) => response.notifications),
    staleTime: 60 * 1000,
    ...options,
  });
}
