import { storeToRefs } from 'pinia';

import { useAppStore } from '@/app/store';

export function useGlobalLoader() {
  const { busy, busyDescription } = storeToRefs(useAppStore());

  function toggle(string?: string) {
    if (busy.value) {
      busy.value = false;
      busyDescription.value = undefined;
      return;
    }
    busyDescription.value = string;
    busy.value = true;
  }

  return { busy, description: busyDescription, toggle };
}
