<template>
  <Transition
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    enter-active-class="transition-opacity duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    leave-active-class="transition-opacity duration-200"
  >
    <div
      v-if="busy"
      class="bg-background/70 fixed inset-0 z-50 flex flex-col items-center justify-center backdrop-blur-sm"
    >
      <Spinner class="mb-2" />
      <p class="text-muted-foreground">{{ description }}</p>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { Spinner } from 'ui/src/components/spinner';

import { useGlobalLoader } from '@/shared/hooks';

const { busy, description } = useGlobalLoader();
</script>
