import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { API_PREFIX, API_ROOT } from '@/shared/config';

export interface CancellableRequest<T> {
  request: () => Promise<T>;
  cancel: () => void;
}

export const baseRequest = axios.create({ baseURL: `${API_ROOT}${API_PREFIX}` });

export const cancellableRequest = <T>(config: AxiosRequestConfig): CancellableRequest<T> => {
  const source = axios.CancelToken.source();

  return {
    request: () =>
      baseRequest({ ...config, cancelToken: source.token }).then((response) => response.data),
    cancel: source.cancel,
  };
};

export default function <T>(config: AxiosRequestConfig): Promise<T> {
  return baseRequest(config).then((response) => response.data);
}
