import { z } from 'zod';

export const TaskSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.object({
    progress: z.string(),
    completed: z.string(),
  }),
});

export type Task = z.infer<typeof TaskSchema>;
