import { storeToRefs } from 'pinia';

import { useAppStore } from '@/app/store';

export default function () {
  const { isSidebarOpened } = storeToRefs(useAppStore());

  if (isSidebarOpened.value) {
    isSidebarOpened.value = false;
  }
}
