<script setup lang="ts">
import '@/shared/css/components/markdown.css';

import { keepPreviousData, useMutation, useQueryClient } from '@tanstack/vue-query';
import { marked } from 'marked';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from 'ui/src/components/sheet';
import { Spinner } from 'ui/src/components/spinner';
import { ref } from 'vue';

import {
  markNotificationAsRead,
  type Notification,
  notificationsQueries,
  useNotifications,
} from '@/entities/notification';
import { formatDateTime } from '@/shared/lib/date';

const open = defineModel<boolean>('open');

const queryClient = useQueryClient();
const page = ref(1);
const query = ref('');

const currentMutateNotificationId = ref<number | undefined>();

const { data: notifications } = useNotifications(
  { page: page.value, query: query.value },
  {
    placeholderData: keepPreviousData,
  },
);

const { mutate: read, isPending: isDismissing } = useMutation({
  mutationFn: (id: number) => markNotificationAsRead(id),
  onSettled: () => {
    queryClient.invalidateQueries({
      queryKey: notificationsQueries.list({ query: query.value, page: page.value }),
    });
  },
});

function dismiss(notification: Notification) {
  currentMutateNotificationId.value = notification.id;
  read(notification.id);
}
</script>

<template>
  <Sheet v-model:open="open">
    <SheetContent>
      <SheetHeader>
        <SheetTitle>Notifications</SheetTitle>
        <SheetDescription> Stay up to date with the latest news and updates. </SheetDescription>
      </SheetHeader>
      <div v-if="notifications">
        <div v-if="notifications.length > 0" class="divide-y">
          <div v-for="notification in notifications" :key="notification.id" class="py-4">
            <div class="mb-2">
              <span
                class="mr-2 rounded px-2 py-1 text-xs font-medium uppercase"
                :class="[notification.important ? 'bg-danger' : 'bg-success']"
              >
                News
              </span>
              <span class="text-xl font-medium">{{ notification.title }}</span>
            </div>
            <div class="markdown" v-html="marked(notification.text)"></div>
            <div class="mt-4 flex items-center justify-between text-xs">
              <time class="font-medium">{{ formatDateTime(notification.createdAt) }}</time>
              <span v-if="notification.viewedByUser" class="text-muted-foreground">Dismissed</span>
              <button v-else class="text-primary font-medium" @click="dismiss(notification)">
                {{
                  currentMutateNotificationId &&
                  currentMutateNotificationId === notification.id &&
                  isDismissing
                    ? 'Dismissing...'
                    : 'Dismiss'
                }}
              </button>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col items-center justify-center pt-16">
          <img
            src="@/assets/states/notifications-bell.svg"
            alt="No notifications"
            class="mx-auto mb-3 h-32"
          />
          <h3 class="text-center font-medium">Your notifications will appear here.</h3>
        </div>
      </div>
      <div v-else class="flex items-center justify-center pt-32">
        <Spinner />
      </div>
    </SheetContent>
  </Sheet>
</template>
