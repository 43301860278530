import { useQueryClient } from '@tanstack/vue-query';
import type { RouteRecordRaw } from 'vue-router';

import type { Profile } from '@/entities/profile';
import { type Project } from '@/entities/project';

export default {
  path: 'projects/:project_uuid',
  component: () => import('@/views/dashboard/projects/project/root.vue'),
  children: [
    {
      path: '',
      name: 'project',
      component: () => import('@/views/dashboard/projects/project/resources.vue'),
    },
    {
      path: 'settings',
      name: 'project/settings',
      beforeEnter: (to) => {
        const queryClient = useQueryClient();

        const project = queryClient.getQueryData<Project>(['project', to.params.project_uuid]);
        const profile = queryClient.getQueryData<Profile>(['profile']);

        if (project?.ownerUuid !== profile?.uuid) {
          return { name: '404' };
        }
      },
      component: () => import('@/views/dashboard/projects/project/settings.vue'),
    },
  ],
} satisfies RouteRecordRaw;
