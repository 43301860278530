import type { Ref } from 'vue';
import { onMounted, onUnmounted, ref } from 'vue';

const defaultEvents = ['mousedown', 'touchstart'];

export function useClickAway(
  callback: (e: Event) => void,
  events = defaultEvents,
): { element: Ref<HTMLElement | undefined> } {
  const element = ref<HTMLElement>();

  const handler = (e: Event) => {
    if (element.value && !element.value.contains(e.target as HTMLElement)) {
      callback(e);
    }
  };

  onMounted(() => {
    events.forEach((evtName) => document.addEventListener(evtName, handler));
  });

  onUnmounted(() => {
    events.forEach((evtName) => document.removeEventListener(evtName, handler));
  });

  return { element };
}
