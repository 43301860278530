import type { RouteRecordRaw } from 'vue-router';

import buckets from './buckets';
import volumes from './volumes';

export default {
  path: 'storages',
  name: 'storages',
  component: () => import('@/views/dashboard/storages/root.vue'),
  children: [volumes, buckets],
} satisfies RouteRecordRaw;
