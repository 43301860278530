<template>
  <Popover v-model:open="open">
    <PopoverTrigger as-child>
      <Button class="group hidden gap-2 md:flex">
        <RocketIcon class="h-3.5 w-3.5 fill-white" />
        <div class="flex items-center whitespace-nowrap">
          <span class="hidden md:inline-block">Deploy</span>
          <ChevronDownIcon
            class="ml-1 h-4 w-4 transform transition duration-200 ease-in-out group-data-[state=open]:rotate-180"
          />
        </div>
      </Button>
    </PopoverTrigger>

    <PopoverContent
      align="end"
      class="max-h-[var(--radix-popover-content-available-height)] w-[550px] space-y-4 overflow-y-auto"
    >
      <div class="space-y-2">
        <div v-for="(items, key) in options" :key="key">
          <template v-if="items.length > 0">
            <h4 class="mb-2 font-medium capitalize">{{ key }}</h4>
            <div class="grid sm:grid-cols-2">
              <div v-for="item in items" :key="item.slug">
                <RouterLink
                  :to="item.to"
                  :class="
                    cn(
                      buttonVariants({ variant: 'ghost' }),
                      'h-auto w-full justify-start p-2 text-left',
                    )
                  "
                >
                  <RenderFeatureIcon width="40" height="40" :name="item.slug" />
                  <div class="ml-2">
                    <h4 class="font-medium">{{ item.label }}</h4>
                    <span class="text-muted-foreground font-normal">{{ item.text }}</span>
                  </div>
                </RouterLink>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="IS_HELPDESK_ENABLED"
        class="bg-muted flex items-center justify-between rounded-lg p-4"
      >
        <div class="flex items-center">
          <RenderFeatureIcon width="40" height="40" name="support" />
          <div class="ml-2">
            <h4 class="font-medium">Need help?</h4>
            <p class="text-muted-foreground">Please contact our support team.</p>
          </div>
        </div>
        <RouterLink to="/ticket/create" :class="cn(buttonVariants())">Create ticket</RouterLink>
      </div>
    </PopoverContent>
  </Popover>
</template>

<script setup lang="ts">
import { ChevronDownIcon, RocketIcon } from 'lucide-vue-next';
import { Button, buttonVariants } from 'ui/src/components/button';
import { Popover, PopoverContent, PopoverTrigger } from 'ui/src/components/popover';
import { cn } from 'ui/src/lib';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useProfile } from '@/entities/profile';
import { IS_HELPDESK_ENABLED } from '@/shared/config';
import RenderFeatureIcon from '@/shared/ui/render-feature-icon.vue';

const route = useRoute();
const open = ref(false);
const { data: profile } = useProfile();

const options = computed(() => {
  return {
    compute: [
      {
        label: 'Stacklet',
        text: 'Create cloud server.',
        slug: 'stacklet',
        to: {
          name: 'stacklet/create',
        },
      },
      ...(profile.value?.features.includes('kubernetes')
        ? [
            {
              label: 'Kubernetes',
              text: 'Deploy k8s cluster.',
              slug: 'kubernetes',
              to: {
                name: 'kubernetes/create',
              },
            },
          ]
        : []),
    ],
    networking: [
      ...(profile.value?.features.includes('floating_ips')
        ? [
            {
              label: 'Floating IP',
              text: 'Reserve IP addresses.',
              slug: 'floating_ip',
              to: {
                name: 'networking/floating-ips',
              },
            },
          ]
        : []),
      {
        label: 'Domains/DNS',
        text: 'Route your existing domains.',
        slug: 'domain',
        to: {
          name: 'networking/domains',
        },
      },
      ...(profile.value?.features.includes('firewalls')
        ? [
            {
              label: 'Firewall',
              text: 'Secure your Stacklet.',
              slug: 'firewall',
              to: {
                name: 'networking/firewalls',
              },
            },
          ]
        : []),
      ...(profile.value?.features.includes('load_balancers')
        ? [
            {
              label: 'Load balancer',
              text: 'Distribute traffic for stacklets.',
              slug: 'load_balancer',
              to: {
                name: 'loadbalancer/create',
              },
            },
          ]
        : []),
      ...(profile.value?.features.includes('vpc')
        ? [
            {
              label: 'Virtual Private Cloud',
              text: 'Secure your cloud env.',
              slug: 'vpc',
              to: {
                name: 'networking/vpc',
              },
            },
          ]
        : []),
    ],
    storage: [
      ...(profile.value?.features.includes('volumes')
        ? [
            {
              label: 'Volume',
              text: 'Add storage to stacklet.',
              slug: 'volume',
              to: {
                name: 'volumes',
              },
            },
          ]
        : []),
      ...(profile.value?.features.includes('buckets')
        ? [
            {
              label: 'Bucket',
              text: 'Create s3 storage.',
              slug: 'bucket',
              to: {
                name: 'bucket/create',
              },
            },
          ]
        : []),

      ...(profile.value?.features.includes('databases')
        ? [
            {
              label: 'Database',
              text: 'Deploy your database.',
              slug: 'database',
              to: {
                name: 'database/create',
              },
            },
          ]
        : []),
    ],
    others: [
      ...(profile.value?.features.includes('container_registry')
        ? [
            {
              label: 'Container Registry',
              text: 'A place for docker images.',
              slug: 'registry',
              to: {
                name: 'container-registry',
              },
            },
          ]
        : []),
    ],
  };
});

watch(route, () => {
  open.value = false;
});
</script>
