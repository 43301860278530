import type { RouteRecordRaw } from 'vue-router';

import { onboardingGuard } from './onboarding-guard';

export default {
  meta: { profile: true },
  path: '/onboarding',
  name: 'onboarding',
  component: () => import('@/views/onboarding.vue'),
  beforeEnter: onboardingGuard,
} satisfies RouteRecordRaw;
