import type { UseQueryOptions } from '@tanstack/vue-query';
import { useQuery } from '@tanstack/vue-query';
import type { Ref } from 'vue';

import type { Team } from '@/entities/team';
import { getTeams } from '@/entities/team';
import type { ResponseMeta } from '@/shared/api';

interface Response {
  teams: Team[];
  meta: ResponseMeta;
}

export function useTeams(
  params?: Ref<{ page?: number; query?: string }>,
  options?: Omit<UseQueryOptions<Response>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: ['teams', params],
    queryFn: () => getTeams(params?.value),
    ...options,
  });
}
