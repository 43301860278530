import { createRouter, createWebHistory } from 'vue-router';

import type { UserFeature } from '@/entities/profile';
import Root from '@/layouts/root.vue';
import notFound from '@/router/404';
import internalError from '@/router/500';
import auth from '@/router/auth';
import { beforeEach } from '@/router/before-each';
import console from '@/router/console';
import billing from '@/router/dashboard/billing';
import getHelp from '@/router/dashboard/get-help';
import onboarding from '@/router/onboarding';
import support from '@/router/support';
import { IS_BILLING_ENABLED, IS_CONSOLE_ENABLED, IS_HELPDESK_ENABLED } from '@/shared/config';

import acceptTeamInvite from './accept-team-invite';
import dashboard from './dashboard';

declare module 'vue-router' {
  interface RouteMeta {
    profile?: boolean;
    feature?: UserFeature;
  }
}

const router = createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'is-active',
  scrollBehavior(to, from, savedPosition) {
    // If navigating to a hash, scroll to the hash
    if (to.hash) {
      return { el: to.hash };
    }

    // If the navigation is triggered by a change in the query params, return savedPosition or false
    if (to.path === from.path) {
      return savedPosition || false;
    }

    // Otherwise, scroll to the top
    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'root',
      component: Root,
      children: [dashboard],
    },
    onboarding,
    acceptTeamInvite,
    auth,
    notFound,
    internalError,
  ],
});

if (IS_HELPDESK_ENABLED) {
  router.addRoute('root', support);
  router.addRoute('dashboard', getHelp);
}

if (IS_BILLING_ENABLED) {
  router.addRoute('dashboard', billing);
}

if (IS_CONSOLE_ENABLED) {
  router.addRoute('root', console);
}

router.beforeEach((to) => beforeEach(to));

export { router };
